import React from 'react';
import { Helmet } from 'react-helmet';

import Navigation from '../components/Navigation/Navigation';

import '../styles/home.scss';

// @ts-ignore
import logo from '../images/logo.svg';
// @ts-ignore
import githubIcon from '../images/github-icon.svg';
// @ts-ignore
import linkedinIcon from '../images/linkedin-icon.svg'

import { setMetaData } from '../helpers/meta';

const App = () => {
  return (
    <div>
      {setMetaData('Steven Duval Ruilova | Home')}
      <>
      <Navigation/>
      <div className='pageWrapper'>
        <div className="mainContainer">
          <img alt="logo" src={logo}/>

          <div className="copyContainer">
            <p>I'm <span>Steven Duval Ruilova</span></p>

            <p>
              A <span>Product Manager @Walmart</span></p>

            <p>
              Currently living and working out of <span>San Diego, California</span> 🏄‍♂️ 🧗‍♂️
            </p>
          </div>

          <div className="socialsContainer">
            <a href="https://github.com/sduval215" target="__blank">
              <img alt="github" src={githubIcon}/>
            </a>
            <a href="https://www.linkedin.com/in/steven-duval-ruilova-10879174/" target="__blank">
              <img alt="linkedin" src={linkedinIcon}/>
            </a>
          </div>
        </div>
      </div>
    </>
    </div>
  )
}

export default App;
